import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Form, FormGroup, Row, Col } from 'reactstrap';
import { FieldError } from 'client/site-modules/shared/components/field-error/field-error';
import { PUB_STATES } from 'client/constants/pub-states';

import { StyledMakeDropdown as MakeDropdown } from 'client/site-modules/shared/components/vehicle-dropdowns/make-dropdown';
import { StyledModelDropdown as ModelDropdown } from 'client/site-modules/shared/components/vehicle-dropdowns/model-dropdown';
import { StyledYearDropdown as YearDropdown } from 'client/site-modules/shared/components/vehicle-dropdowns/year-dropdown';
import classnames from 'classnames';

const DEFAULT_MMY_ERROR_MESSAGES = {
  make: 'Please select a make',
  model: 'Please select a model',
  year: 'Please select a year',
};

const PropEntities = {
  Col: {
    PropTypes: PropTypes.shape({
      xs: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number,
    }),
    DefaultProps: {
      xs: 12,
      md: null,
      lg: null,
      xl: null,
    },
  },
};

export class MmyDropdowns extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    className: PropTypes.string,
    dropdownClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    formGroupClassName: PropTypes.string,
    prepopulatedVehicle: PropTypes.shape({
      submodels: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      makeSlug: PropTypes.string,
      modelSlug: PropTypes.string,
      name: PropTypes.string,
    }),
    dropdownPlaceholders: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      submodel: PropTypes.string,
      year: PropTypes.string,
      style: PropTypes.string,
    }),
    dropdownLabels: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      submodel: PropTypes.string,
      year: PropTypes.string,
      style: PropTypes.string,
    }),
    dropdownTrackingIds: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      submodel: PropTypes.string,
      year: PropTypes.string,
      style: PropTypes.string,
    }),
    errorMessages: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      submodel: PropTypes.string,
      year: PropTypes.string,
      style: PropTypes.string,
    }),
    displayError: PropTypes.shape({
      make: PropTypes.bool,
      model: PropTypes.bool,
      year: PropTypes.bool,
      style: PropTypes.bool,
    }),
    useStyledSelects: PropTypes.bool,
    hasModelsDropdown: PropTypes.bool,
    hasSubmodelsDropdown: PropTypes.bool,
    hasStylesDropdown: PropTypes.bool,
    includeNextYear: PropTypes.bool,
    hideLabels: PropTypes.bool,
    earliestYear: PropTypes.number,
    useMostPopularStylesDropdown: PropTypes.bool,
    rowClassName: PropTypes.string,
    colClassName: PropTypes.string,
    dropdownColSize: PropTypes.shape({
      year: PropEntities.Col.PropTypes,
      make: PropEntities.Col.PropTypes,
      model: PropEntities.Col.PropTypes,
      submodel: PropEntities.Col.PropTypes,
      style: PropEntities.Col.PropTypes,
    }),
    suffix: PropTypes.string,
    pubStates: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    children: null,
    className: null,
    dropdownClassName: null,
    onChange: noop,
    onFocus: noop,
    labelClassName: 'dropdown-label label text-gray-dark mb-0_5',
    formGroupClassName: 'mb-0',
    prepopulatedVehicle: {},
    dropdownLabels: {},
    dropdownTrackingIds: {},
    dropdownPlaceholders: {
      make: 'Select',
      model: 'Select',
      submodel: 'Select',
      year: 'Select',
      style: 'Select',
    },
    errorMessages: DEFAULT_MMY_ERROR_MESSAGES,
    displayError: {},
    useStyledSelects: false,
    hasModelsDropdown: false,
    hasSubmodelsDropdown: true,
    hasStylesDropdown: true,
    includeNextYear: true,
    hideLabels: false,
    earliestYear: 1990,
    useMostPopularStylesDropdown: false,
    rowClassName: null,
    colClassName: null,
    dropdownColSize: {
      year: PropEntities.Col.DefaultProps,
      make: PropEntities.Col.DefaultProps,
      model: PropEntities.Col.DefaultProps,
      submodel: PropEntities.Col.DefaultProps,
      style: PropEntities.Col.DefaultProps,
    },
    suffix: '',
    pubStates: [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED],
  };

  state = {};

  static getDerivedStateFromProps(props, state) {
    const { year, makeSlug, modelSlug } = props.prepopulatedVehicle;

    if (makeSlug && state.prevPrepopulatedVehicle !== props.prepopulatedVehicle) {
      const newState = {
        year: year && year.toString(),
        make: makeSlug,
        model: modelSlug,
      };

      newState.prevPrepopulatedVehicle = props.prepopulatedVehicle;

      return newState;
    }

    return {};
  }

  onChange = () => {
    const { make, model, year } = this.state;
    this.props.onChange({ make, model, year });
  };

  setMake = ({ slug }) => {
    this.setState(
      {
        make: slug,
        model: null,
        year: null,
      },
      this.onChange
    );
  };

  setModel = ({ slug }) => {
    this.setState(
      {
        model: slug,
        year: null,
      },
      this.onChange
    );
  };

  setYear = ({ year }) => {
    this.setState(
      {
        year,
      },
      this.onChange
    );
  };

  render() {
    const {
      className,
      dropdownClassName,
      labelClassName,
      formGroupClassName,
      children,
      dropdownPlaceholders,
      dropdownLabels,
      dropdownTrackingIds,
      includeNextYear,
      displayError,
      errorMessages,
      hideLabels,
      earliestYear,
      rowClassName,
      colClassName,
      dropdownColSize,
      suffix,
      pubStates,
    } = this.props;
    const { make, model, submodel, style, year } = this.state;

    return (
      <Form autoComplete="off" className={className}>
        <Row className={rowClassName}>
          <Col {...dropdownColSize.make} className={colClassName}>
            <FormGroup className={classnames(formGroupClassName, 'form-group')} noMargin>
              <MakeDropdown
                name="select-make"
                toggle={dropdownPlaceholders.make}
                labelText={dropdownLabels.make}
                valueKey="slug"
                labelKey="name"
                onChange={this.setMake}
                value={make}
                className={dropdownClassName}
                labelClassName={labelClassName}
                data-tracking-id={dropdownTrackingIds.make}
                id={`mmy-select-make${suffix}`}
                isLabelHidden={hideLabels}
                pubStates={pubStates}
              />
              {displayError.make && <FieldError id="makeError" classes="mb-0 mt-0_25" error={errorMessages.make} />}
            </FormGroup>
          </Col>

          <Col {...dropdownColSize.model} className={colClassName}>
            <FormGroup className={classnames(formGroupClassName, 'form-group')} noMargin>
              <ModelDropdown
                make={make ? { slug: make } : null}
                name="select-model"
                toggle={dropdownPlaceholders.model}
                labelText={dropdownLabels.model}
                valueKey="slug"
                labelKey="name"
                value={model}
                onChange={this.setModel}
                className={dropdownClassName}
                labelClassName={labelClassName}
                data-tracking-id={dropdownTrackingIds.model}
                id={`mmy-select-model${suffix}`}
                isLabelHidden={hideLabels}
                pubStates={pubStates}
              />
              {displayError.model && <FieldError id="modelError" classes="mb-0 mt-0_25" error={errorMessages.model} />}
            </FormGroup>
          </Col>

          <Col {...dropdownColSize.year} className={colClassName}>
            <FormGroup className={classnames(formGroupClassName, 'form-group')} noMargin>
              <YearDropdown
                name="select-year"
                toggle={dropdownPlaceholders.year}
                labelText={dropdownLabels.year}
                valueKey="year"
                labelKey="year"
                make={make ? { slug: make } : null}
                model={model ? { slug: model } : null}
                value={year}
                onChange={this.setYear}
                onFocus={this.props.onFocus}
                className={dropdownClassName}
                labelClassName={labelClassName}
                data-tracking-id={dropdownTrackingIds.year}
                id={`mmy-select-year${suffix}`}
                isLabelHidden={hideLabels}
                includeNextYear={includeNextYear}
                earliestYear={earliestYear}
                pubStates={pubStates}
              />
              {displayError.year && <FieldError id="yearError" classes="mb-0 mt-0_25" error={errorMessages.year} />}
            </FormGroup>
          </Col>
        </Row>

        {typeof children === 'function' ? children({ make, model, submodel, year, style }) : children}
      </Form>
    );
  }
}
